import React from 'react'
import CoffeeBeanSVG from '../../images/coffee-bean.svg'
import Layout from '../../containers/Layout'
import { Listing } from 'web-components'
import { StrengthMeter } from 'web-components'
import { imageCDNUrl } from '../../helpers'

import products from '../../config/text/products.json'

const coffee = products.coffee

class Coffee extends React.Component {
  render () {
    const coffeeCard = product => {
      return <Listing
        key={product.name}
        description={product.description}
        footnote={product.footnote}
        img={{
          ...product.img,
          src: imageCDNUrl(product.img.src)
        }}
        name={product.name}
        {...{ points: product.points }}
      >
        <div>
          <span className='h5'>Origins</span>
          <p>{product.origins}</p>
        </div>
        <div>
          <span className='h5'>Strength</span>
          {typeof product.strength === 'object' ? (
            Object.keys(product.strength).map(key => {
              return (
                <div>
                  <StrengthMeter strength={product.strength[key]} svg={CoffeeBeanSVG}/>
                  <span>&nbsp;&nbsp;&#183;&nbsp;&nbsp;{key}</span>
                </div>
              )
            })
          ) : (
            <div>
              <StrengthMeter strength={product.strength} svg={CoffeeBeanSVG}/>
            </div>
          )}
        </div>
      </Listing>
    }
    return (
      <Layout heroSrc='heros/kent-coffee-beans-bags.jpg' heroText={coffee.title} title={coffee.meta.title} description={coffee.meta.description}>
        <div className='coffee container'>
          <div className='row'>
            <div className='col-xs-12 pb-2'>
              {coffee.description.map(d => <p>{d}</p>)}
            </div>
          </div>
          <div className='row'>
            {coffee.products.map(product => {
              return product.products ? (
                <React.Fragment>
                  <div className='pt-3'>
                    <h3 className='pb-2'>{product.name}</h3>
                    {product.description &&
                      <p>{product.description}</p>
                    }
                  </div>
                  {product.products.map(p => {
                    return coffeeCard(p)
                  })}
                </React.Fragment>
              ) : coffeeCard(product)
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Coffee
